var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showButton,
              expression: "showButton",
            },
          ],
          on: {
            click: function ($event) {
              return _vm.openPopupInvoived()
            },
          },
        },
        [
          _vm.cellValue
            ? _c("vs-button", { attrs: { size: "small", color: "success" } }, [
                _vm._v("Facturado"),
              ])
            : _c("vs-button", { attrs: { size: "small", color: "danger" } }, [
                _vm._v("No facturado"),
              ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: { title: _vm.title, active: _vm.popupActive },
          on: {
            "update:active": function ($event) {
              _vm.popupActive = $event
            },
          },
        },
        [
          _c("p", [
            _vm._v(" Marcar como\n      "),
            _vm.cellValue
              ? _c("span", { staticClass: "text-dark uppercase" }, [
                  _vm._v("no facturado"),
                ])
              : _c("span", { staticClass: "text-dark uppercase" }, [
                  _vm._v("facturado"),
                ]),
          ]),
          _c(
            "vs-button",
            {
              directives: [
                {
                  name: "can",
                  rawName: "v-can",
                  value: "change_panel_invoiced_campaign",
                  expression: "'change_panel_invoiced_campaign'",
                },
              ],
              staticClass: "float-right",
              attrs: { color: "primary", type: "filled" },
              on: {
                click: function ($event) {
                  return _vm.changeStateInvoiced()
                },
              },
            },
            [_vm._v("Cambiar")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }