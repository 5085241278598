var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "campaigns-container" },
        [
          _c(
            "vx-card",
            {
              staticClass: "mb-2 campaign-filters",
              attrs: { title: "Filtros" },
            },
            [
              _c(
                "vs-row",
                {
                  attrs: {
                    "vs-align": "flex-end",
                    "vs-type": "flex",
                    "vs-justify": "space-between",
                    "vs-w": "12",
                  },
                },
                [
                  _c(
                    "vs-col",
                    { attrs: { "vs-w": "2" } },
                    [
                      _c("p", [_vm._v("Tipo de fecha:")]),
                      _c(
                        "v-select",
                        {
                          attrs: {
                            id: "type",
                            options: _vm.optionsType,
                            label: "text",
                            clearable: false,
                          },
                          on: { input: _vm.deleteOthersFilters },
                          model: {
                            value: _vm.selectType,
                            callback: function ($$v) {
                              _vm.selectType = $$v
                            },
                            expression: "selectType",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v("Nada que mostrar.")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.selectType.value
                    ? _c(
                        "vs-col",
                        { attrs: { "vs-w": "3" } },
                        [
                          _c("p", [_vm._v("Intervalo del Reporte:")]),
                          _vm._l(_vm.getFiltersPickers, function (picker, key) {
                            return _c(
                              "div",
                              { key: key },
                              [
                                _vm.selectType.value === picker.condition
                                  ? _c(
                                      "vs-row",
                                      [
                                        _c(
                                          "vs-col",
                                          { attrs: { "vs-w": "6" } },
                                          [
                                            _c("flat-pickr", {
                                              attrs: {
                                                config: _vm.configPicker,
                                                placeholder: "Fecha Desde",
                                              },
                                              on: {
                                                "on-change": _vm.onFromChange,
                                              },
                                              model: {
                                                value: picker.vModel.from,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    picker.vModel,
                                                    "from",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "picker.vModel.from",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "vs-col",
                                          { attrs: { "vs-w": "6" } },
                                          [
                                            _c("flat-pickr", {
                                              attrs: {
                                                config: _vm.configPickerTo,
                                                placeholder: "Fecha Hasta",
                                              },
                                              on: {
                                                "on-change": _vm.onToChange,
                                              },
                                              model: {
                                                value: picker.vModel.to,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    picker.vModel,
                                                    "to",
                                                    $$v
                                                  )
                                                },
                                                expression: "picker.vModel.to",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "vs-col",
                    { attrs: { "vs-w": "2" } },
                    [
                      _c("country-select", {
                        on: {
                          value: function ($event) {
                            _vm.filters.countries = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    { attrs: { "vs-w": "1" } },
                    [
                      _c("label", [_vm._v("Mostrar Cumplimiento")]),
                      _c(
                        "vs-switch",
                        {
                          attrs: { "vs-icon-on": "check", color: "success" },
                          model: {
                            value: _vm.filters.showImpressions,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "showImpressions", $$v)
                            },
                            expression: "filters.showImpressions",
                          },
                        },
                        [
                          _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                            _vm._v("Si"),
                          ]),
                          _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                            _vm._v("No"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    { attrs: { "vs-w": "1" } },
                    [
                      _c("label", [_vm._v("Mostrar reporte en USD/ARS")]),
                      _c(
                        "vs-switch",
                        {
                          attrs: { "vs-icon-on": "check", color: "success" },
                          model: {
                            value: _vm.filters.convertCurrency,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "convertCurrency", $$v)
                            },
                            expression: "filters.convertCurrency",
                          },
                        },
                        [
                          _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                            _vm._v("Si"),
                          ]),
                          _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                            _vm._v("No"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.selectType.value === "sales"
                    ? _c(
                        "vs-col",
                        { attrs: { "vs-w": "1" } },
                        [
                          _c("label", [_vm._v("Consumo en rango")]),
                          _c(
                            "vs-switch",
                            {
                              attrs: {
                                "vs-icon-on": "check",
                                color: "success",
                              },
                              model: {
                                value: _vm.filters.showConsumptionInRange,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filters,
                                    "showConsumptionInRange",
                                    $$v
                                  )
                                },
                                expression: "filters.showConsumptionInRange",
                              },
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "on" }, slot: "on" },
                                [_vm._v("Si")]
                              ),
                              _c(
                                "span",
                                { attrs: { slot: "off" }, slot: "off" },
                                [_vm._v("No")]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "vs-col",
                    { attrs: { "vs-w": "1" } },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "mr-3 mb-2",
                          on: { click: _vm.searchData },
                        },
                        [_vm._v("Buscar")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vs-tabs",
            { staticClass: "table-selector", attrs: { alignment: "fixed" } },
            [
              _c("vs-tab", {
                attrs: { label: "Cobros" },
                on: {
                  click: function ($event) {
                    return _vm.showChargesTable()
                  },
                },
              }),
              _c("vs-tab", {
                attrs: { label: "Pagos" },
                on: {
                  click: function ($event) {
                    return _vm.showPaymentsTable()
                  },
                },
              }),
            ],
            1
          ),
          _c("campaigns-charges", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showingChargesTable,
                expression: "showingChargesTable",
              },
            ],
            ref: "campaignsCharges",
            attrs: { filters: _vm.filters, filterType: _vm.selectType },
          }),
          _c("campaigns-payments", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showingPaymentsTable,
                expression: "showingPaymentsTable",
              },
            ],
            ref: "campaignsPayments",
            attrs: { filters: _vm.filters },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }