<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <vx-card class="campaigns-container">
      <vx-card class="mb-2 campaign-filters" title="Filtros">
        <vs-row vs-align="flex-end" vs-type="flex" vs-justify="space-between" vs-w="12">
          <vs-col vs-w="2">
            <p>Tipo de fecha:</p>
            <v-select
              id="type"
              v-model="selectType"
              :options="optionsType"
              label="text"
              :clearable="false"
              @input="deleteOthersFilters"
            >
              <span slot="no-options">Nada que mostrar.</span>
            </v-select>
          </vs-col>
          <vs-col vs-w="3" v-if="selectType.value">
            <p>Intervalo del Reporte:</p>
            <div v-for="(picker, key) in getFiltersPickers" :key="key">
              <vs-row v-if="selectType.value === picker.condition">
                <vs-col vs-w="6">
                  <flat-pickr
                    :config="configPicker"
                    v-model="picker.vModel.from"
                    placeholder="Fecha Desde"
                    @on-change="onFromChange"
                  />
                </vs-col>
                <vs-col vs-w="6">
                  <flat-pickr
                    :config="configPickerTo"
                    v-model="picker.vModel.to"
                    placeholder="Fecha Hasta"
                    @on-change="onToChange"
                  />
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
          <vs-col vs-w="2">
            <country-select v-on:value="filters.countries = $event" />
          </vs-col>
          <vs-col vs-w="1">
            <label>Mostrar Cumplimiento</label>
            <vs-switch
              vs-icon-on="check"
              color="success"
              v-model="filters.showImpressions"
            >
              <span slot="on">Si</span>
              <span slot="off">No</span>
            </vs-switch>
          </vs-col>
          <vs-col vs-w="1">
            <label>Mostrar reporte en USD/ARS</label>
            <vs-switch
              vs-icon-on="check"
              color="success"
              v-model="filters.convertCurrency"
            >
              <span slot="on">Si</span>
              <span slot="off">No</span>
            </vs-switch>
          </vs-col>
          <vs-col vs-w="1" v-if="selectType.value === 'sales'">
            <label>Consumo en rango</label>
            <vs-switch
              vs-icon-on="check"
              color="success"
              v-model="filters.showConsumptionInRange"
            >
              <span slot="on">Si</span>
              <span slot="off">No</span>
            </vs-switch>
          </vs-col>
          <vs-col vs-w="1">
            <vs-button @click="searchData" class="mr-3 mb-2">Buscar</vs-button>
          </vs-col>
        </vs-row>
      </vx-card>
      <vs-tabs class="table-selector" alignment="fixed">
        <vs-tab label="Cobros" v-on:click="showChargesTable()"></vs-tab>
        <vs-tab label="Pagos" v-on:click="showPaymentsTable()"></vs-tab>
      </vs-tabs>
      <!-- CHARGES TABLE -->
      <campaigns-charges
        v-show="showingChargesTable"
        :filters="filters"
        :filterType="selectType"
        ref="campaignsCharges"
      ></campaigns-charges>
      <!-- PAYMENTS TABLE -->
      <campaigns-payments
        v-show="showingPaymentsTable"
        :filters="filters"
        ref="campaignsPayments"
      ></campaigns-payments>
    </vx-card>
  </div>
</template>

<style>
.ag-header-group-cell.column-group-1 {
  background-color: #b39ddb;
}
.ag-header-group-cell.column-group-1:hover {
  background-color: #9575cd !important;
}
.ag-header-group-cell.column-group-2 {
  background-color: #ef9a9a;
}
.ag-header-group-cell.column-group-2:hover {
  background-color: #e57373 !important;
}
.ag-header-group-cell.column-group-3 {
  background-color: #a5d6a7;
}
.ag-header-group-cell.column-group-3:hover {
  background-color: #81c784 !important;
}

.campaign-filters .vx-card__header {
  padding: 10px 10px 0px 15px !important;
}

.table-selector .vs-tabs--content {
  padding: 1px;
}

.table-selector .vs-tabs--btn {
  padding: 7px!important;
}

.campaigns-container .vx-card__body {
  padding:  15px !important;
}

.font-12 * {
  font-size: 12px !important;
}

.height-32 {
  height: 32px !important;
}

/* Add !important to display: none added by v-show directive */
*[style*='display: none'] {
  display: none !important;
}
</style>

<script>
import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey(
  'CompanyName=Servoy B.V.,LicensedApplication=Servoy,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=200,AssetReference=AG-010463,ExpiryDate=11_October_2021_[v2]_MTYzMzkwNjgwMDAwMA==4c6752fe4cb2066ab1f0e9c572bc7491'
)
import { AgGridVue } from 'ag-grid-vue'
import CampaignsCharges from './CampaignsCharges.vue'
import CampaignsPayments from './CampaignsPayments.vue'
import flatPickr from 'vue-flatpickr-component'
import { Spanish as SpanishLocale } from 'flatpickr/dist/l10n/es.js'
import vSelect from 'vue-select'
import CountrySelect from '../components/country-select/CountrySelect.vue'
import moment from 'moment'

import './css/campaings.scss'
import 'flatpickr/dist/flatpickr.css'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

export default {
  components: {
    AgGridVue,
    flatPickr,
    CampaignsCharges,
    CampaignsPayments,
    vSelect,
    CountrySelect
  },
  data() {
    return {
      // Variables
      showingChargesTable: false,
      showingPaymentsTable: false,
      filters: {
        startDate: {
          from: moment()
            .clone()
            .subtract(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD'),
          to: moment()
            .clone()
            .subtract(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD')
        },
        endDate: {
          from: '',
          to: ''
        },
        createdAt: {
          from: '',
          to: ''
        },
        gatewayLastUpdate: {
          from: '',
          to: ''
        },
        salesPeriod: {
          from: '',
          to: ''
        },
        showImpressions: false,
        convertCurrency : true,
        showConsumptionInRange : false,
        countries: []
      },
      // Config TimePicker
      configPicker: {
        minDate: null,
        locale: SpanishLocale,
        altInput: true,
        altFormat: 'd/m/Y'
      },
      configPickerTo: {
        minDate: null,
        locale: SpanishLocale,
        altInput: true,
        altFormat: 'd/m/Y'
      },
      optionsType: [
        { value: 'init', text: 'Inicio' },
        { value: 'finish', text: 'Fin' },
        { value: 'creation', text: 'Creación' },
        { value: 'payment', text: 'Pago' },
        { value: 'sales', text: 'Ventas' }
      ],
      selectType: { value: 'init', text: 'Inicio' }
    }
  },
  watch: {},
  computed: {
    getFiltersPickers() {
      return [
        {
          vModel: this.filters.startDate,
          condition: 'init'
        },
        {
          vModel: this.filters.endDate,
          condition: 'finish'
        },
        {
          vModel: this.filters.createdAt,
          condition: 'creation'
        },
        {
          vModel: this.filters.gatewayLastUpdate,
          condition: 'payment'
        },
        {
          vModel: this.filters.salesPeriod,
          condition: 'sales'
        }
      ]
    }
  },
  methods: {
    // Charges table methods
    showChargesTable() {
      this.showingChargesTable = true
      this.showingPaymentsTable = false
    },
    showPaymentsTable() {
      this.showingChargesTable = false
      this.showingPaymentsTable = true
    },
    onFromChange(selectedDates, dateStr) {
      this.$set(this.configPickerTo, 'minDate', dateStr)
    },
    onToChange(selectedDates, dateStr) {
      this.$set(this.configPicker, 'maxDate', dateStr)
    },
    searchData() {
      this.$refs.campaignsCharges.getGridData()
      this.$refs.campaignsPayments.getGridData()
    },
    deleteOthersFilters() {
      const arrKeys = Object.keys(this.filters)
      arrKeys.forEach((key) => {
        let filter = this.filters[key]
        if (!filter || typeof filter !== 'object') {
          filter = null
          return
        }
        filter.to = null
        filter.from = null
      })
    }
  },
  mounted() {
    this.showingChargesTable = true
  }
}
</script>
