<template>
  <div>
    <div v-show="showButton" @click="openPopupInvoived()">
      <vs-button v-if="cellValue" size="small" color="success">Facturado</vs-button>
      <vs-button v-else size="small" color="danger">No facturado</vs-button>
    </div>
    <vs-popup :title="title" :active.sync="popupActive">
      <p> Marcar como
        <span v-if="cellValue" class="text-dark uppercase">no facturado</span>
        <span v-else class="text-dark uppercase">facturado</span>
      </p>
      <vs-button v-can="'change_panel_invoiced_campaign'" @click="changeStateInvoiced()" color="primary" type="filled" class="float-right">Cambiar</vs-button>
    </vs-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import appConfig from '@/../appConfig.js'

export default Vue.extend({
  data () {
    return {
      rowValue: null,
      cellValue: null,
      popupActive: false,
      title: null,
      showButton: true
    }
  },
  beforeMount () {
    this.cellValue = this.getValueToDisplay(this.params)
    this.rowValue = this.params.data
    this.title = `Campaña ID: ${this.rowValue.id}`

    if (this.rowValue.payment_method === 'cash') {
      this.showButton = false
    }
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh (params) {
      this.cellValue = this.getValueToDisplay(params)
    },

    openPopupInvoived () {
      this.popupActive = !this.popupActive
    },

    getValueToDisplay (params) {
      return params.value
    },

    changeStateInvoiced () {
      this.$http.post(`${appConfig.apiUrl}/panel/campaigns/${this.rowValue.id}/invoiced`, {
        params: {}
      })
        .then((response) => {
          this.cellValue = response.data.invoiced

          this.$vs.notify({
            title: this.title,
            text: `${this.cellValue ? 'FACTURADA' : 'NO FACTURADA'}`,
            color: 'primary'
          })
        })
        .catch((error) => {
          console.log(error)
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            color: 'danger'
          })
        })
        .then(() => {
          // always executed
          this.popupActive = !this.popupActive
        })
    }
  }
})
</script>