<template>
  <div>
    <vx-card>
      <!-- CHARGES TABLE -->
      <!-- TABLE ACTION ROW -->
      <div class="flex flex-wrap justify-between items-center font-12">
        <!-- ITEMS PER PAGE -->
        <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium height-32">
              <span class="mr-2"
                >{{
                  chargesCurrentPage * chargesPaginationPageSize -
                  (chargesPaginationPageSize - 1)
                }}
                -
                {{
                  chargesGridData.length -
                    chargesCurrentPage * chargesPaginationPageSize >
                  0
                    ? chargesCurrentPage * chargesPaginationPageSize
                    : chargesGridData.length
                }}
                of {{ chargesGridData.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="chargesGridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="chargesGridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="chargesGridApi.paginationSetPageSize(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="chargesGridApi.paginationSetPageSize(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
          <vs-input class="mb-4 md:mb-0 mr-4" v-model="chargesSearchQuery" @input="updateChargesSearchQuery" placeholder="Buscar..." size="small"/>
          <vs-button class="mb-4 md:mb-0 mr-4" @click="chargesGridApi.exportDataAsExcel({rowHeight: 20})" size="small">Exportar Excel</vs-button>
          <vs-button
            class="mb-4 md:mb-0 mr-4"
            v-if="this.countSelected"
            @click="openPopupChangeState('invoiced')"
            v-can="'change_panel_invoiced_campaign'"
            size="small"
          >
              Cambiar estado facturación ({{ this.countSelected }})
          </vs-button>
          <vs-button
            class="mb-4 md:mb-0 mr-4"
            v-if="this.countSelected"
            @click="openPopupChangeState('charged')"
            v-can="'change_panel_charged_campaign'"
            size="small"
          >
            Cambiar estado cobrado ({{ this.countSelected }})
          </vs-button>
          <vx-tooltip text="Refrescar datos">
            <vs-button
              class="mb-4 md:mb-0 mr-4"
              radius
              color="primary"
              type="border"
              icon-pack="feather"
              icon="icon-refresh-cw"
              @click="getGridData()"
              size="small"
            ></vs-button>
          </vx-tooltip>
          <vx-tooltip color="dark" text="Limpiar filtros">
            <vs-button
              radius
              color="danger"
              type="border"
              icon-pack="feather"
              icon="icon-x"
              @click="resetFilters"
              size="small"
              ></vs-button>
          </vx-tooltip>
        </div>
      </div>
      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="chargesGridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="chargesColumnDefs"
        :defaultColDef="chargesDefaultColDef"
        :rowData="chargesGridData"
        rowSelection="multiple"
        rowMultiSelectWithClick="true"
        :enableRangeSelection="true"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :getContextMenuItems="getChargesContextMenuItems"
        :pagination="true"
        :paginationPageSize="chargesPaginationPageSize"
        :suppressPaginationPanel="true"
        :frameworkComponents="frameworkComponents"
        tooltipShowDelay="0"
        @selection-changed="onSelectionChanged"
        :headerHeight="'35'"
        :floatingFiltersHeight="'35'"
      >
      </ag-grid-vue>
      <vs-pagination
        :total="chargesTotalPages"
        :max="chargesMaxPageNumbers"
        v-model="chargesCurrentPage"
      />
    </vx-card>
    <!-- Popup change campaigns state-->
    <vs-popup :title="popupActiveChangeStateTitle" :active.sync="popupActiveChangeState">
      <vs-table noDataText="Error" :data="selectedRows">
        <template slot="thead">
          <vs-th>ID</vs-th>
          <vs-th>Nombre</vs-th>
          <vs-th>Estado actual</vs-th>
          <vs-th>Nuevo estado</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].id">
              {{ data[indextr].id }}
            </vs-td>
            <vs-td :data="data[indextr].name">
              {{ data[indextr].name }}
            </vs-td>
            <vs-td :data="data[indextr].type_campaign" v-if="popupActiveChangeStateType == 'invoiced'">
              <vs-chip color="success" v-if="data[indextr].invoiced">FACTURADA</vs-chip>
              <vs-chip color="danger" v-else>NO FACTURADA</vs-chip>
            </vs-td>
            <vs-td :data="data[indextr].type_campaign" v-if="popupActiveChangeStateType == 'invoiced'">
              <vs-chip color="success" v-if="!data[indextr].invoiced">FACTURADA</vs-chip>
              <vs-chip color="danger" v-else>NO FACTURADA</vs-chip>
            </vs-td>
            <vs-td :data="data[indextr].start_date" v-if="popupActiveChangeStateType == 'charged'">
              <vs-chip color="success" v-if="data[indextr].charged">COBRADO</vs-chip>
              <vs-chip color="danger" v-else>NO COBRADO</vs-chip>
            </vs-td>
            <vs-td :data="data[indextr].start_date" v-if="popupActiveChangeStateType == 'charged'">
              <vs-chip color="success" v-if="!data[indextr].charged">COBRADO</vs-chip>
              <vs-chip color="danger" v-else>NO COBRADO</vs-chip>
            </vs-td>

          </vs-tr>
        </template>
      </vs-table>
      <vs-button @click="changeState()" color="primary" type="filled" class="float-right mt-2">Cambiar</vs-button>
    </vs-popup>
    <div class="centerx">
      <vs-popup title="ODCs" :active.sync="poModalActive">
        <vs-table noDataText="Error" :data="poModalData">
          <template slot="thead">
            <vs-th>Archivo</vs-th>
            <vs-th>Fecha creación</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td style="display: flex; flex-direction: row;">
                <a style="padding-left: 10px;" :href="tr.file" target="_blank">
                  Ver
                </a>
              </vs-td>
              <vs-td>
                {{ dateFormatter(tr.created_at) }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-popup>
    </div>
  </div>
</template>

<style>
/* Add !important to display: none added by v-show directive */
*[style*="display: none"] {
  display: none !important;
}
</style>

<script>
import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey('CompanyName=Servoy B.V.,LicensedApplication=Servoy,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=200,AssetReference=AG-010463,ExpiryDate=11_October_2021_[v2]_MTYzMzkwNjgwMDAwMA==4c6752fe4cb2066ab1f0e9c572bc7491')
import { AgGridVue } from 'ag-grid-vue'
import moment from 'moment'
import appConfig from '@/../appConfig.js'

import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import _spanishLocaleText from '@/constants/spanishLocaleText.js'

import InvoicedRenderer from './CampaignsInvoicedRenderer.vue'
import ChargedRenderer from './CampaignsChargedRenderer.vue'

import ProfitPercentageTooltip from './ProfitPercentageTooltip.vue'

export default {
  name: 'campaigns-charges',
  components: {
    AgGridVue
  },
  props: {
    filters: {
      type: Object,
      required: true
    },
    filterType: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      // Charges grid options
      chargesSearchQuery: '',
      chargesGridOptions: {
        localeText: _spanishLocaleText.getText()
      },
      chargesMaxPageNumbers: 7,
      chargesGridApi: null,
      chargesDefaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
        headerHeight: 10
      },
      chargesColumnDefs: [
        {
          headerName: 'Campaña',
          headerClass: 'column-group-1',
          children: [
            {
              headerName: 'ID',
              field: 'id',
              width: 150,
              filter: true,
              sort: 'desc',
              checkboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              headerCheckboxSelection: true,
              pinned: 'left'
            },
            {
              headerName: 'Nombre Campaña',
              field: 'name',
              filter: true,
              width: 300,
              wrapText: true
            },
            {
              headerName: 'DSP',
              valueGetter: this.externalDspGetter,
              filter: true,
              width: 200,
              wrapText: true
            },
            {
              headerName: 'Fecha de Creación',
              valueGetter: this.createdAtGetter,
              filter: true,
              width: 200
            },
            {
              headerName: 'Fecha de Pago',
              valueGetter: this.gatewayLastUpdateGetter,
              filter: true,
              width: 250
            },
            {
              headerName: 'Fecha de Inicio',
              valueGetter: this.startDateGetter,
              filter: true,
              width: 180
            },
            {
              headerName: 'Fecha de Fin',
              valueGetter: this.endDateGetter,
              filter: true,
              width: 150
            },
            {
              headerName: 'Duración Real',
              field: 'days_in_sales_period',
              filter: true,
              width: 150,
              colId: 'days_in_sales_period'
            },
            {
              headerName: 'Estado de Pago',
              field: 'payment_status',
              refData: this.paymentStatusMapping(),
              filter: true,
              width: 150
            },
            {
              headerName: 'Aprobación',
              field: 'status',
              refData: this.campaignStatusMapping(),
              filter: true,
              width: 150
            },
            {
              headerName: 'Cumplimiento',
              valueGetter: this.complianceGetter,
              filter: true,
              width: 150,
              colId: 'compliance'
            },
            {
              headerName: 'Tipo',
              field: 'smart_campaign',
              valueGetter: this.campaignTypeNameGetter,
              filter: true,
              width: 150
            }
          ]
        },
        {
          headerName: 'Operación',
          headerClass: 'column-group-2',
          children: [
            {
              headerName: 'Divisa',
              field: 'currency',
              width: 100,
              filter: true
            },
            {
              headerName: 'Precio Final C/I',
              valueGetter: this.campaignCostWithTaxGetter,
              valueFormatter: this.currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 350
            },
            {
              headerName: 'Consumo en rango S/I',
              field: 'total_price_consumed',
              valueFormatter: this.currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 350,
              colId: 'total_price_consumed'
            },
            {
              headerName: 'Impresiones prometidas',
              field: 'total_promised_shows',
              filter: 'agNumberColumnFilter',
              width: 350,
              colId: 'total_promised_shows'
            },
            {
              headerName: 'Impresiones consumidas en rango',
              field: 'total_shows_consumed',
              filter: 'agNumberColumnFilter',
              width: 350,
              colId: 'total_shows_consumed'
            },
            {
              headerName: 'Precio Final S/I',
              field: 'total_price_with_profit',
              valueFormatter: this.currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 350
            },
            {
              headerName: 'Ganancia agencia',
              valueGetter: this.agencyProfitGetter,
              valueFormatter: this.currencyFormatter,
              tooltipComponent: 'profitPercentageTooltip',
              tooltipValueGetter: this.agencyProfitPercentagetoolTipValueGetter,
              filter: 'agNumberColumnFilter',
              width: 180
            },
            {
              headerName: 'Ganancia plataforma',
              valueGetter: this.platformProfitGetter,
              valueFormatter: this.currencyFormatter,
              tooltipComponent: 'profitPercentageTooltip',
              tooltipValueGetter: this.platformProfitPercentagetoolTipValueGetter,
              filter: 'agNumberColumnFilter',
              width: 230
            },
            {
              headerName: 'Ganancia LatinAd',
              valueGetter: this.latinAdProfitGetter,
              valueFormatter: this.currencyFormatter,
              filter: 'agNumberColumnFilter',
              colId: 'latinAdProfit',
              hide: true,
              width: 230
            },
            {
              headerName: 'Ganancia Partner',
              valueGetter: this.partnerProfitGetter,
              valueFormatter: this.currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 230
            },
            {
              headerName: 'Ganancia Exchange',
              valueGetter: this.exchangeProfitGetter,
              valueFormatter: this.currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 230
            },
            {
              headerName: 'Pago pantalleros',
              field: 'total_price',
              valueFormatter: this.currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 260
            },
            {
              headerName: 'Monto a cobrar C/I',
              valueGetter: this.amountReceivableWithTaxGetter,
              valueFormatter: this.currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 285
            },
            {
              headerName: 'Monto a cobrar S/I',
              field: 'total_price_with_commission',
              valueFormatter: this.currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 280
            },
            {
              headerName: 'Costos internacionales',
              field: 'withholding_tax_amount',
              valueFormatter: this.currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 250
            },
            {
              headerName: 'ODC',
              field: 'purchase_order_agency',
              filter: false,
              width: 120,
              hide: false,
              cellStyle: {textAlign: 'center'},
              cellRenderer: (params) => {
                if (params.value) {
                  const button = document.createElement('a')
                  button.innerText = 'Ver'
                  button.addEventListener('click', () => this.openPoModal(params.value))
                  return button
                }
              }
            },
            {
              headerName: 'Cotización del día',
              field: 'currency_rate',
              valueFormatter: this.currencyCheck,
              filter: 'agNumberColumnFilter',
              width: 250
            }
          ]
        },
        {
          headerName: 'Impuestos',
          headerClass: 'column-group-4',
          children: [
            {
              headerName: 'Local',
              valueGetter: this.taxGetter,
              width: 150
            },
            {
              headerName: 'Internacional',
              valueGetter: this.withholdingTaxGetter,
              width: 150
            }
          ]
        },
        {
          headerName: 'Facturación',
          headerClass: 'column-group-3',
          children: [
            {
              headerName: 'País',
              valueGetter: this.countryGetter,
              filter: true,
              width: 200
            },
            {
              headerName: 'Nombre Cliente',
              valueGetter: this.fullNameGetter,
              filter: true,
              width: 200
            },
            {
              headerName: 'Tax ID Cliente',
              field: 'billing_information_doc_number',
              filter: true,
              width: 150
            },
            {
              headerName: 'Email Cliente',
              valueGetter: this.emailGetter,
              filter: true,
              width: 150
            },
            {
              headerName: 'Teléfono Cliente',
              valueGetter: this.phoneGetter,
              filter: true,
              width: 150
            },
            {
              headerName: 'Forma de Pago',
              valueGetter: this.paymentMethodGetter,
              filter: true,
              width: 250
            },
            {
              headerName: 'Regional',
              field: 'multi_country',
              width: 120,
              hide: false,
              cellStyle: {paddingLeft: '5px', paddingRight: '5px'}
            },
            {
              headerName: 'Regional',
              field: 'multi_country',
              hide: true
            },
            {
              headerName: 'Facturado',
              field: 'invoiced',
              cellRenderer: 'invoicedRenderer',
              width: 120,
              hide: false,
              cellStyle: {paddingLeft: '5px', paddingRight: '5px'},
              pinned: 'left',
              filter: true,
              refData: this.campaignBillingsMapping()
            },
            {
              headerName: 'Facturado',
              field: 'invoiced',
              hide: true
            },
            {
              headerName: 'Cobrado',
              field: 'charged',
              cellRenderer: 'chargedRenderer',
              width: 120,
              hide: false,
              cellStyle: {paddingLeft: '5px', paddingRight: '5px'},
              pinned: 'left',
              filter: true,
              refData: this.campaignChargesMapping()
            },
            {
              headerName: 'Cobrado',
              field: 'charged',
              hide: true
            }
          ]
        },
        {
          headerName: 'Usuario',
          headerClass: 'column-group-4',
          children: [
            {
              headerName: 'Responsable Agencia',
              valueGetter: this.userNameGetter,
              filter: true,
              width: 250
            },
            {
              headerName: 'Email Responsable',

              field: 'user_email',
              filter: true,
              width: 250
            },
            {
              headerName: 'Agencia',
              valueGetter: this.companyNameGetter,
              filter: true,
              width: 300
            },
            {
              headerName: 'Holding',
              valueGetter: this.holdingNameGetter,
              filter: true,
              width: 200
            }
          ]
        }
      ],
      chargesGridData: [],
      frameworkComponents: null,
      countSelected:0,
      popupActiveChangeState: false,
      popupActiveChangeStateTitle: 'Cambiar estado',
      popupActiveChangeStateType: '',
      selectedRows: [],
      poModalActive: false,
      poModalData: null
    }
  },
  watch: {
    '$store.state.windowWidth' (val) {
      if (val <= 576) {
        this.chargesMaxPageNumbers = 4
        this.chargesGridOptions.columnApi.setColumnPinned('email', null)
      } else {
        this.chargesGridOptions.columnApi.setColumnPinned('email', 'left')
      }
    }
  },
  computed: {
    // Charges table computed
    chargesPaginationPageSize () {
      if (this.chargesGridApi) return this.chargesGridApi.paginationGetPageSize()
      else return 100
    },
    chargesTotalPages () {
      if (this.chargesGridApi) return this.chargesGridApi.paginationGetTotalPages()
      else return 0
    },
    chargesCurrentPage: {
      get () {
        if (this.chargesGridApi) return this.chargesGridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.chargesGridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    updateChargesSearchQuery (val) {
      this.chargesGridApi.setQuickFilter(val)
    },
    getGridData () {
      this.countSelected = 0
      this.chargesGridApi.showLoadingOverlay()
      this.$http.get(`${appConfig.apiUrl}/panel/campaigns`, {
        params: {
          filters: this.filters
        }
      })
        .then((response) => {
          this.chargesGridData = response.data.rowData
          this.chargesGridApi.hideOverlay()
          // Show LatinAd profit only to argentinian staff based on API response
          if (this.chargesGridData.some(p => !!p.percentage_profit_platform)) {
            this.chargesGridOptions.columnApi.setColumnVisible('latinAdProfit', true)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .then(() => {
          // Only show period columns column if filtering by sales period and showConsumptionInRange is true
          const showPeriodColumns = this.filterType.value === 'sales' && this.filters.showConsumptionInRange
          this.chargesGridOptions.columnApi.setColumnVisible('total_price_consumed', showPeriodColumns)
          this.chargesGridOptions.columnApi.setColumnVisible('total_shows_consumed', showPeriodColumns)
          this.chargesGridOptions.columnApi.setColumnVisible('total_promised_shows', showPeriodColumns)
          this.chargesGridOptions.columnApi.setColumnVisible('days_in_sales_period', showPeriodColumns)
          this.chargesGridOptions.columnApi.setColumnVisible('compliance', showPeriodColumns)
        })
    },

    // Getters and Formatters for Charges Ag Table
    fullNameGetter (params) {
      const strName = `${params.data.billing_information_first_name || ''} ${params.data.billing_information_last_name || ''}`
      const strNameTrim = strName.trim()

      if (strNameTrim) {
        return strName
      } else {
        return params.data.company_name ? params.data.company_name : '-'
      }

    },
    emailGetter (params) {
      if (params.data.company_id) {
        return params.data.email
      } else {
        return '-'
      }
    },
    phoneGetter (params) {
      if (params.data.company_id) {
        return params.data.phone
      } else {
        return '-'
      }
    },
    countryGetter (params) {
      if (params.data.invoice_issuing_country_name) {
        return params.data.invoice_issuing_country_name
      } else {
        return `${params.data.billing_information_address_country || ''} ${params.data.billing_information_address_state || ''}`
      }
    },
    campaignTypeNameGetter (params) {
      return params.data.smart_campaign ? 'Programática' : 'Tradicional'
    },
    agencyProfitGetter (params) {
      return parseFloat(params.data.total_price_with_profit - params.data.total_price_with_commission).toFixed(2)
    },
    agencyProfitPercentagetoolTipValueGetter (params) {
      const totalValue = params.data.total_price_with_profit
      const agencyProfit = parseFloat(params.data.total_price_with_profit - params.data.total_price_with_commission).toFixed(2)
      const profitPercentage = parseFloat((agencyProfit * 100) / totalValue).toFixed(2)
      return {profitPercentage: !isNaN(profitPercentage) ? profitPercentage : 0.0}
    },
    complianceGetter (params) {
      return params.data.total_shows_consumed || params.data.total_shows_consumed === 0 ? `${parseFloat((params.data.total_shows_consumed * 100) / params.data.total_promised_shows).toFixed(2)}%` : '-'
    },
    platformProfitGetter (params) {
      return parseFloat(parseFloat(params.data.total_price_with_commission) - parseFloat(params.data.total_price) - parseFloat(params.data.withholding_tax_amount)).toFixed(2)
    },
    latinAdProfitGetter(params) {
      const oldProfit =  parseFloat(parseFloat(parseFloat(params.data.total_price_with_commission) - parseFloat(params.data.total_price) - parseFloat(params.data.withholding_tax_amount)) * params.data.percentage_profit_platform / 100).toFixed(2)
      const newProfit =  parseFloat(parseFloat(params.data.total_price * (params.data.markup_platform / 100)) * (params.data.percentage_profit_platform / 100)).toFixed(2)

      if (newProfit > 0) {
        return newProfit
      } else {
        return oldProfit
      }
    },
    partnerProfitGetter(params) {
      return parseFloat(parseFloat(params.data.total_price * (params.data.markup_partner / 100))).toFixed(2)
    },
    exchangeProfitGetter(params) {
      return parseFloat(parseFloat(params.data.total_price_with_profit * (params.data.markup_external_dsp / 100))).toFixed(2)
    },
    platformProfitPercentagetoolTipValueGetter (params) {
      const totalValue = params.data.total_price_with_profit
      const platformProfit = parseFloat(params.data.total_price_with_commission - params.data.total_price).toFixed(2)
      const profitPercentage = parseFloat((platformProfit * 100) / totalValue).toFixed(2)
      return {profitPercentage: !isNaN(profitPercentage) ? profitPercentage : 0.0}
    },
    createdAtGetter (params) {
      return this.dateFormatter(params.data.created_at)
    },
    gatewayLastUpdateGetter (params) {
      return this.dateFormatter(params.data.gateway_last_update)
    },
    startDateGetter (params) {
      return this.dateFormatter(params.data.start_date)
    },
    endDateGetter (params) {
      return this.dateFormatter(params.data.end_date)
    },
    taxGetter (params) {
      return this.percentageFormatter(params.data.tax)
    },
    withholdingTaxGetter (params) {
      return this.percentageFormatter(params.data.withholding_tax)
    },
    dateFormatter (params) {
      return params ? moment(params, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'
    },
    percentageFormatter (params) {
      return params ? `${parseFloat(params)}%` : '-'
    },
    currencyFormatter (params) {
      return this.$options.filters.currencyFormatter(params.value || 0)
    },
    currencyCheck (params) {
      return params.value ?  this.currencyFormatter(params) : '-'
    },
    paymentMethodGetter (params) {
      const gateway = {
        publinet: 'Publinet',
        mercadopago_basic: 'Mercadopago',
        mercadopago_custom: 'Mercadopago'
      }

      return  params.data.gateway ? `${gateway[params.data.gateway]} (${params.data.payment_method})` : '-'
    },
    userNameGetter (params) {
      return `${params.data.first_name} ${params.data.last_name}`
    },
    companyNameGetter (params) {
      return params.data.company_id ? `${params.data.company_name} (${params.data.company_id})` : '-'
    },
    holdingNameGetter (params) {
      return params.data.holding_id ? `${params.data.holding_name} (${params.data.holding_id})` : '-'
    },
    campaignCostWithTaxGetter (params) {
      return parseFloat(parseFloat(params.data.total_price_with_profit) * (1 + (parseFloat(params.data.tax) / 100))).toFixed(2)
    },
    amountReceivableWithTaxGetter (params) {
      return parseFloat(parseFloat(params.data.total_price_with_commission) * (1 + (parseFloat(params.data.tax) / 100))).toFixed(2)
    },
    externalDspGetter ({ data }) {
      return data.external_dsp_id ? `${data.external_dsp_name} (${data.external_dsp_exchange})` : 'LatinAd'
    },
    // Data mappings
    campaignStatusMapping () {
      return {
        pending: 'Pendiente',
        ready: 'Lista'
      }
    },
    paymentStatusMapping () {
      return {
        pending: 'Pendiente',
        approved: 'Aprobado',
        in_process: 'Procesando',
        in_mediation: 'En mediación',
        rejected: 'Rechazado',
        cancelled: 'Cancelado',
        refunded: 'Reintegrado',
        charge_back: 'Contracargo'
      }
    },
    campaignBillingsMapping () {
      return {
        0: 'No facturado',
        1: 'Facturado'
      }
    },
    campaignChargesMapping () {
      return {
        0: 'No cobrado',
        1: 'Cobrado'
      }
    },
    // Right click context menu
    getChargesContextMenuItems () {
      const selectedItems = this.chargesGridApi.getSelectedNodes()
      const thereAreSelectedItems = Array.isArray(selectedItems) && selectedItems.length
      return [
        'copy',
        'copyWithHeaders',
        {
          name: 'Exportar seleccionados',
          icon: '<span class="ag-icon ag-icon-save" unselectable="on"></span>',
          disabled: !thereAreSelectedItems,
          subMenu: [
            {
              name: 'CSV (.csv)',
              action: () => {
                this.chargesGridApi.exportDataAsCsv({onlySelected: true, columnSeparator:';'})
              }
            },
            {
              name: 'Excel (.xlsx)',
              action: () => {
                this.chargesGridApi.exportDataAsExcel({onlySelected: true})
              }
            }
          ]
        }
      ]
    },
    resetFilters () {
      this.chargesGridApi.setFilterModel(null)
      this.chargesGridApi.setSortModel(null)
    },
    onSelectionChanged () {
      const selectedRows = this.chargesGridApi.getSelectedRows()
      this.countSelected = selectedRows.length
      this.selectedRows = selectedRows
    },
    openPopupChangeState (type) {
      this.popupActiveChangeStateType = type
      switch (type) {
      case 'invoiced':
        this.popupActiveChangeStateTitle = 'Cambiar estado facturado'
        break
      case 'charged':
        this.popupActiveChangeStateTitle = 'Cambiar estado cobrado'
        break
      default:
        break
      }
      this.popupActiveChangeState = !this.popupActiveChangeState
    },
    changeState () {
      const campaignsId = []
      this.selectedRows.forEach((row) => {
        campaignsId.push(row.id)
      })
      this.apiCall(campaignsId)
    },
    apiCall (campaignsId) {
      this.$http.put(`${appConfig.apiUrl}/panel/campaigns/states`, {
        campaigns: campaignsId,
        type: this.popupActiveChangeStateType
      })
        .then((response) => {
          this.$vs.notify({
            title: response.data.message,
            text: ':)',
            color: 'primary'
          })
          // Reload table
          this.getGridData()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            color: 'danger'
          })
        })
        .then(() => {
          // always executed
          this.popupActiveChangeState = !this.popupActiveChangeState
        })
    },
    openPoModal (data) {
      this.poModalActive = true
      this.poModalData = JSON.parse(data)
    }
  },
  beforeMount () {
    this.frameworkComponents = {
      invoicedRenderer: InvoicedRenderer,
      chargedRenderer: ChargedRenderer,
      profitPercentageTooltip: ProfitPercentageTooltip
    }
  },
  mounted () {
    this.chargesGridApi = this.chargesGridOptions.api
    // this.getGridData()
  }
}
</script>
