var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vx-card",
        [
          _c(
            "div",
            {
              staticClass:
                "flex flex-wrap justify-between items-center font-12",
            },
            [
              _c(
                "div",
                { staticClass: "mb-4 md:mb-0 mr-4 ag-grid-table-actions-left" },
                [
                  _c(
                    "vs-dropdown",
                    {
                      staticClass: "cursor-pointer",
                      attrs: { "vs-trigger-click": "" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium height-32",
                        },
                        [
                          _c("span", { staticClass: "mr-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.chargesCurrentPage *
                                  _vm.chargesPaginationPageSize -
                                  (_vm.chargesPaginationPageSize - 1)
                              ) +
                                "\n              -\n              " +
                                _vm._s(
                                  _vm.chargesGridData.length -
                                    _vm.chargesCurrentPage *
                                      _vm.chargesPaginationPageSize >
                                    0
                                    ? _vm.chargesCurrentPage *
                                        _vm.chargesPaginationPageSize
                                    : _vm.chargesGridData.length
                                ) +
                                "\n              of " +
                                _vm._s(_vm.chargesGridData.length)
                            ),
                          ]),
                          _c("feather-icon", {
                            attrs: {
                              icon: "ChevronDownIcon",
                              svgClasses: "h-4 w-4",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-dropdown-menu",
                        [
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.chargesGridApi.paginationSetPageSize(
                                    20
                                  )
                                },
                              },
                            },
                            [_c("span", [_vm._v("20")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.chargesGridApi.paginationSetPageSize(
                                    50
                                  )
                                },
                              },
                            },
                            [_c("span", [_vm._v("50")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.chargesGridApi.paginationSetPageSize(
                                    100
                                  )
                                },
                              },
                            },
                            [_c("span", [_vm._v("100")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.chargesGridApi.paginationSetPageSize(
                                    150
                                  )
                                },
                              },
                            },
                            [_c("span", [_vm._v("150")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-wrap items-center justify-between ag-grid-table-actions-right",
                },
                [
                  _c("vs-input", {
                    staticClass: "mb-4 md:mb-0 mr-4",
                    attrs: { placeholder: "Buscar...", size: "small" },
                    on: { input: _vm.updateChargesSearchQuery },
                    model: {
                      value: _vm.chargesSearchQuery,
                      callback: function ($$v) {
                        _vm.chargesSearchQuery = $$v
                      },
                      expression: "chargesSearchQuery",
                    },
                  }),
                  _c(
                    "vs-button",
                    {
                      staticClass: "mb-4 md:mb-0 mr-4",
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.chargesGridApi.exportDataAsExcel({
                            rowHeight: 20,
                          })
                        },
                      },
                    },
                    [_vm._v("Exportar Excel")]
                  ),
                  this.countSelected
                    ? _c(
                        "vs-button",
                        {
                          directives: [
                            {
                              name: "can",
                              rawName: "v-can",
                              value: "change_panel_invoiced_campaign",
                              expression: "'change_panel_invoiced_campaign'",
                            },
                          ],
                          staticClass: "mb-4 md:mb-0 mr-4",
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.openPopupChangeState("invoiced")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            Cambiar estado facturación (" +
                              _vm._s(this.countSelected) +
                              ")\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  this.countSelected
                    ? _c(
                        "vs-button",
                        {
                          directives: [
                            {
                              name: "can",
                              rawName: "v-can",
                              value: "change_panel_charged_campaign",
                              expression: "'change_panel_charged_campaign'",
                            },
                          ],
                          staticClass: "mb-4 md:mb-0 mr-4",
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.openPopupChangeState("charged")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          Cambiar estado cobrado (" +
                              _vm._s(this.countSelected) +
                              ")\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "vx-tooltip",
                    { attrs: { text: "Refrescar datos" } },
                    [
                      _c("vs-button", {
                        staticClass: "mb-4 md:mb-0 mr-4",
                        attrs: {
                          radius: "",
                          color: "primary",
                          type: "border",
                          "icon-pack": "feather",
                          icon: "icon-refresh-cw",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getGridData()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vx-tooltip",
                    { attrs: { color: "dark", text: "Limpiar filtros" } },
                    [
                      _c("vs-button", {
                        attrs: {
                          radius: "",
                          color: "danger",
                          type: "border",
                          "icon-pack": "feather",
                          icon: "icon-x",
                          size: "small",
                        },
                        on: { click: _vm.resetFilters },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
            attrs: {
              gridOptions: _vm.chargesGridOptions,
              columnDefs: _vm.chargesColumnDefs,
              defaultColDef: _vm.chargesDefaultColDef,
              rowData: _vm.chargesGridData,
              rowSelection: "multiple",
              rowMultiSelectWithClick: "true",
              enableRangeSelection: true,
              colResizeDefault: "shift",
              animateRows: true,
              floatingFilter: true,
              getContextMenuItems: _vm.getChargesContextMenuItems,
              pagination: true,
              paginationPageSize: _vm.chargesPaginationPageSize,
              suppressPaginationPanel: true,
              frameworkComponents: _vm.frameworkComponents,
              tooltipShowDelay: "0",
              headerHeight: "35",
              floatingFiltersHeight: "35",
            },
            on: { "selection-changed": _vm.onSelectionChanged },
          }),
          _c("vs-pagination", {
            attrs: {
              total: _vm.chargesTotalPages,
              max: _vm.chargesMaxPageNumbers,
            },
            model: {
              value: _vm.chargesCurrentPage,
              callback: function ($$v) {
                _vm.chargesCurrentPage = $$v
              },
              expression: "chargesCurrentPage",
            },
          }),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.popupActiveChangeStateTitle,
            active: _vm.popupActiveChangeState,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupActiveChangeState = $event
            },
          },
        },
        [
          _c(
            "vs-table",
            {
              attrs: { noDataText: "Error", data: _vm.selectedRows },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ data }) {
                    return _vm._l(data, function (tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr },
                        [
                          _c("vs-td", { attrs: { data: data[indextr].id } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(data[indextr].id) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", { attrs: { data: data[indextr].name } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(data[indextr].name) +
                                "\n          "
                            ),
                          ]),
                          _vm.popupActiveChangeStateType == "invoiced"
                            ? _c(
                                "vs-td",
                                {
                                  attrs: { data: data[indextr].type_campaign },
                                },
                                [
                                  data[indextr].invoiced
                                    ? _c(
                                        "vs-chip",
                                        { attrs: { color: "success" } },
                                        [_vm._v("FACTURADA")]
                                      )
                                    : _c(
                                        "vs-chip",
                                        { attrs: { color: "danger" } },
                                        [_vm._v("NO FACTURADA")]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.popupActiveChangeStateType == "invoiced"
                            ? _c(
                                "vs-td",
                                {
                                  attrs: { data: data[indextr].type_campaign },
                                },
                                [
                                  !data[indextr].invoiced
                                    ? _c(
                                        "vs-chip",
                                        { attrs: { color: "success" } },
                                        [_vm._v("FACTURADA")]
                                      )
                                    : _c(
                                        "vs-chip",
                                        { attrs: { color: "danger" } },
                                        [_vm._v("NO FACTURADA")]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.popupActiveChangeStateType == "charged"
                            ? _c(
                                "vs-td",
                                { attrs: { data: data[indextr].start_date } },
                                [
                                  data[indextr].charged
                                    ? _c(
                                        "vs-chip",
                                        { attrs: { color: "success" } },
                                        [_vm._v("COBRADO")]
                                      )
                                    : _c(
                                        "vs-chip",
                                        { attrs: { color: "danger" } },
                                        [_vm._v("NO COBRADO")]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.popupActiveChangeStateType == "charged"
                            ? _c(
                                "vs-td",
                                { attrs: { data: data[indextr].start_date } },
                                [
                                  !data[indextr].charged
                                    ? _c(
                                        "vs-chip",
                                        { attrs: { color: "success" } },
                                        [_vm._v("COBRADO")]
                                      )
                                    : _c(
                                        "vs-chip",
                                        { attrs: { color: "danger" } },
                                        [_vm._v("NO COBRADO")]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    })
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", [_vm._v("ID")]),
                  _c("vs-th", [_vm._v("Nombre")]),
                  _c("vs-th", [_vm._v("Estado actual")]),
                  _c("vs-th", [_vm._v("Nuevo estado")]),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "vs-button",
            {
              staticClass: "float-right mt-2",
              attrs: { color: "primary", type: "filled" },
              on: {
                click: function ($event) {
                  return _vm.changeState()
                },
              },
            },
            [_vm._v("Cambiar")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "centerx" },
        [
          _c(
            "vs-popup",
            {
              attrs: { title: "ODCs", active: _vm.poModalActive },
              on: {
                "update:active": function ($event) {
                  _vm.poModalActive = $event
                },
              },
            },
            [
              _c(
                "vs-table",
                {
                  attrs: { noDataText: "Error", data: _vm.poModalData },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return _vm._l(data, function (tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr },
                            [
                              _c(
                                "vs-td",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row",
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { "padding-left": "10px" },
                                      attrs: {
                                        href: tr.file,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                Ver\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("vs-td", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.dateFormatter(tr.created_at)) +
                                    "\n            "
                                ),
                              ]),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [_vm._v("Archivo")]),
                      _c("vs-th", [_vm._v("Fecha creación")]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }