<template>
  <div>
    <vx-card>
      <!-- TABLE ACTION ROW -->
      <div class="flex flex-wrap justify-between items-center font-12">
        <!-- ITEMS PER PAGE -->
        <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium  height-32">
              <span class="mr-2">{{ paymentsCurrentPage * paymentsPaginationPageSize - (paymentsPaginationPageSize - 1) }} - {{ paymentsGridData.length - paymentsCurrentPage * paymentsPaginationPageSize > 0 ? paymentsCurrentPage * paymentsPaginationPageSize : paymentsGridData.length }} of {{ paymentsGridData.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="paymentsGridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paymentsGridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paymentsGridApi.paginationSetPageSize(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paymentsGridApi.paginationSetPageSize(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
          <vs-input class="mb-4 md:mb-0 mr-4" v-model="paymentsSearchQuery" @input="updatePaymentsSearchQuery" placeholder="Buscar..." size="small"/>
          <vs-button
              class="mb-4 md:mb-0 mr-4"
              v-if="this.countSelected"
              @click="openPopupChangeState('payment')"
              v-can="'change_panel_payed_content_display'"
              size="small"
            >
              Cambiar estado pago ({{ this.countSelected }})
          </vs-button>
          <vs-button class="mb-4 md:mb-0 mr-4" @click="paymentsGridApi.exportDataAsExcel({rowHeight: 20})" size="small">Exportar Excel</vs-button>
          <vx-tooltip text="Refrescar datos">
            <vs-button
              class="mb-4 md:mb-0 mr-4"
              radius
              color="primary"
              type="border"
              icon-pack="feather"
              icon="icon-refresh-cw"
              @click="getGridData"
              size="small"
              ></vs-button>
          </vx-tooltip>
          <vx-tooltip color="dark" text="Limpiar filtros">
            <vs-button
              radius
              color="danger"
              type="border"
              icon-pack="feather"
              icon="icon-x"
              @click="resetFilters"
              size="small"
              ></vs-button>
          </vx-tooltip>
        </div>
      </div>
      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="paymentsGridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="paymentsColumnDefs"
        :defaultColDef="paymentsDefaultColDef"
        :rowData="paymentsGridData"
        rowSelection="multiple"
        rowMultiSelectWithClick="true"
        :enableRangeSelection="true"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :getContextMenuItems="getPaymentsContextMenuItems"
        :pagination="true"
        :paginationPageSize="paymentsPaginationPageSize"
        :suppressPaginationPanel="true"
        :frameworkComponents="frameworkComponents"
        @selection-changed="onSelectionChanged"
        :headerHeight="'35'"
        :floatingFiltersHeight="'35'"
        >
      </ag-grid-vue>
      <vs-pagination
        :total="paymentsTotalPages"
        :max="paymentsMaxPageNumbers"
        v-model="paymentsCurrentPage" />
    </vx-card>
    <!-- Popup change payment state-->
    <vs-popup :title="popupActivePaymentStateTitle" :active.sync="popupActivePaymentState">
      <vs-table noDataText="Error" :data="selectedRows">
        <template slot="thead">
          <vs-th>ID Campaña</vs-th>
          <vs-th>Nombre Campaña</vs-th>
          <vs-th>Empresa</vs-th>
          <vs-th>Pais</vs-th>
          <vs-th>Estado actual</vs-th>
          <vs-th>Nuevo estado</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].campaign_id">
              {{ data[indextr].campaign_id }}
            </vs-td>
            <vs-td :data="data[indextr].campaign_name">
              {{ data[indextr].campaign_name }}
            </vs-td>
            <vs-td :data="data[indextr].company_name">
              {{ data[indextr].company_name }}
            </vs-td>
            <vs-td :data="data[indextr].display_country">
              {{ data[indextr].display_country }}
            </vs-td>
            <vs-td :data="data[indextr].is_payed">
              <vs-chip color="success" v-if="data[indextr].is_payed">PAGADO</vs-chip>
              <vs-chip color="danger" v-else>NO PAGADO</vs-chip>
            </vs-td>
            <vs-td :data="data[indextr].is_payed">
              <vs-chip color="success" v-if="!data[indextr].is_payed">PAGADO</vs-chip>
              <vs-chip color="danger" v-else>NO PAGADO</vs-chip>
            </vs-td>


          </vs-tr>
        </template>
      </vs-table>
      <vs-button  @click="changeState()" color="primary" type="filled" class="float-right mt-2">Cambiar</vs-button>
    </vs-popup>
    <div class="centerx">
      <vs-popup title="ODCs" :active.sync="poModalActive">
        <vs-table noDataText="Error" :data="poModalData">
          <template slot="thead">
            <vs-th>Archivo</vs-th>
            <vs-th>Fecha creación</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <a style="padding-left: 10px;" :href="tr.file" target="_blank">
                  Ver
                </a>
              </vs-td>
              <vs-td>
                {{ dateFormatter(tr.created_at) }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-popup>
    </div>
  </div>
</template>

<style>
.ag-header-group-cell.column-group-1 {
  background-color: #b39ddb;
}
.ag-header-group-cell.column-group-1:hover {
  background-color: #9575cd !important;
}
.ag-header-group-cell.column-group-2 {
  background-color: #ef9a9a;
}
.ag-header-group-cell.column-group-2:hover {
  background-color: #e57373 !important;
}
.ag-header-group-cell.column-group-3 {
  background-color: #a5d6a7;
}
.ag-header-group-cell.column-group-3:hover {
  background-color: #81c784 !important;
}

/* Add !important to display: none added by v-show directive */
*[style*="display: none"] {
  display: none !important;
}
</style>

<script>
import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey('CompanyName=Servoy B.V.,LicensedApplication=Servoy,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=200,AssetReference=AG-010463,ExpiryDate=11_October_2021_[v2]_MTYzMzkwNjgwMDAwMA==4c6752fe4cb2066ab1f0e9c572bc7491')
import { AgGridVue } from 'ag-grid-vue'
import moment from 'moment'
import appConfig from '@/../appConfig.js'

import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

import PayedRenderer from './CampaignsPaymentsRenderer.vue'

export default {
  name: 'campaigns-payments',
  components: {
    AgGridVue
  },
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      // Payments grid options
      paymentsSearchQuery: '',
      paymentsGridOptions: {
        localeText: this.spanishLocaleText()
      },
      paymentsMaxPageNumbers: 7,
      paymentsGridApi: null,
      paymentsDefaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      paymentsColumnDefs: [
        {
          headerName: 'Campaña',
          headerClass: 'column-group-1',
          children: [
            {
              headerName: 'ID',
              field: 'campaign_id',
              filter: true,
              sort: 'desc',
              checkboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              headerCheckboxSelection: true,
              pinned: 'left',
              lockPinned: true,
              width: 150
            },
            {
              headerName: 'Nombre campaña',
              field: 'campaign_name',
              filter: true,
              width: 300,
              wrapText: true
            },
            {
              headerName: 'DSP',
              valueGetter: this.externalDspGetter,
              filter: true,
              width: 200,
              wrapText: true
            },
            {
              headerName: 'Fecha de Inicio',
              valueGetter: this.fromDateGetter,
              filter: true,
              width: 180
            },
            {
              headerName: 'Fecha de Fin',
              valueGetter: this.toDateGetter,
              filter: true,
              width: 150
            },
            {
              headerName: 'Impresiones prometidas',
              field: 'promised_shows',
              filter: true,
              width: 230
            },
            {
              headerName: 'Impresiones cumplidas',
              field: 'impressions_showed',
              filter: true,
              width: 230
            },
            {
              headerName: 'Impresiones cumplidas en rango',
              field: 'impressions_showed_in_sales_period',
              filter: true,
              width: 230,
              colId: 'impressions_showed_in_sales_period'
            },
            {
              headerName: 'Cumplimiento',
              valueGetter: this.complianceGetter,
              filter: true,
              width: 160
            }
          ]
        },
        {
          headerName: 'Pantalla',
          headerClass: 'column-group-4',
          children: [
            {
              headerName: 'ID Empresa',
              field: 'company_id',
              filter: true,
              width: 150
            },
            {
              headerName: 'Empresa Pantallero',
              field: 'company_name',
              filter: true,
              width: 200
            },
            {
              headerName: 'Holding',
              valueGetter: this.holdingNameGetter,
              filter: true,
              width: 200
            },
            {
              headerName: 'ID Pantalla',
              field: 'display_id',
              filter: true,
              width: 150
            },
            {
              headerName: 'Nombre Pantalla',
              field: 'display_name',
              filter: true,
              width: 200
            },
            {
              headerName: 'País',
              field: 'display_country',
              filter: true,
              width: 150
            }
          ]
        },
        {
          headerName: 'Operación',
          headerClass: 'column-group-2',
          children: [
            {
              headerName: 'Divisa',
              field: 'currency_to_pay',
              width: 100,
              filter: true
            },
            {
              headerName: 'Pago a pantalleros C/I',
              valueGetter: this.amountToPayWithTaxesGetter,
              valueFormatter: this.currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 280
            },
            {
              headerName: 'Pago a pantalleros S/I',
              field: 'paid_amount_local_currency',
              valueFormatter: this.currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 280
            },
            {
              headerName: 'Pago a pantalleros en rango S/I',
              field: 'total_price_consumed',
              valueFormatter: this.currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 280,
              colId: 'total_price_consumed'
            },
            {
              headerName: 'Forma de Pago',
              valueGetter: () => { return '-' },
              filter: true,
              width: 160
            },
            {
              headerName: 'Estado Aprobación',
              field: 'payment_status',
              refData: this.paymentStatusMapping(),
              filter: true,
              width: 150
            },
            {
              headerName: 'Estado de Pago',
              field: 'is_payed',
              cellRenderer: 'payedRenderer',
              filter: true,
              width: 120,
              cellStyle: {paddingLeft: '5px', paddingRight: '5px'},
              pinned: 'left',
              refData: this.simplePaymentStatusMapping()
            },
            {
              headerName: 'Estado de Pago',
              field: 'is_payed',
              hide: true
            },
            {
              headerName: 'ODC',
              field: 'purchase_order',
              filter: false,
              cellRenderer: (params) => {
                if (params.value) {
                  const button = document.createElement('a')
                  button.innerText = 'Ver'
                  button.addEventListener('click', () => this.openPoModal(params.value))
                  return button
                }
              }
            }
          ]
        },
        {
          headerName: 'Conversiones monedas',
          headerClass: 'column-group-3',
          children: [
            {
              headerName: 'Tasa',
              field: 'paid_amount_rate',
              width: 100
            },
            {
              headerName: 'From',
              valueGetter: this.rateFromGetter,
              width: 180
            },
            {
              headerName: 'To',
              valueGetter: this.rateToGetter,
              width: 180
            }
          ]
        },
        {
          headerName: 'Impuestos',
          headerClass: 'column-group-4',
          children: [
            {
              headerName: 'Local',
              valueGetter: this.taxGetter,
              width: 150
            },
            {
              headerName: 'Internacional',
              valueGetter: this.withholdingTaxGetter,
              width: 150
            }
          ]
        }
      ],
      paymentsGridData: [],
      frameworkComponents: null,
      countSelected:0,
      popupActivePaymentState: false,
      popupActivePaymentStateTitle: 'Cambiar estado',
      popupActivePaymentStateType: '',
      selectedRows: [],
      poModalActive: false,
      poModalData: null
    }
  },
  watch: {
    '$store.state.windowWidth' (val) {
      if (val <= 576) {
        this.paymentsMaxPageNumbers = 4
        this.paymentsGridOptions.columnApi.setColumnPinned('email', null)
      } else {
        this.paymentsGridOptions.columnApi.setColumnPinned('email', 'left')
      }
    }
  },
  computed: {
    // Payments table computed
    paymentsPaginationPageSize () {
      if (this.paymentsGridApi) return this.paymentsGridApi.paginationGetPageSize()
      else return 100
    },
    paymentsTotalPages () {
      if (this.paymentsGridApi) return this.paymentsGridApi.paginationGetTotalPages()
      else return 0
    },
    paymentsCurrentPage: {
      get () {
        if (this.paymentsGridApi) return this.paymentsGridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.paymentsGridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    complianceGetter (params) {
      return params.data.impressions_showed || params.data.impressions_showed === 0 ? `${parseFloat((params.data.impressions_showed * 100) / params.data.promised_shows).toFixed(2)}%` : '-'
    },
    rateFromGetter (params) {
      return params.data.paid_amount_rates.from ? params.data.paid_amount_rates.from.currency : '-'
    },
    rateToGetter (params) {
      return params.data.paid_amount_rates.to ? params.data.paid_amount_rates.to.currency : '-'
    },
    amountToPayWithTaxesGetter (params) {
      return parseFloat(parseFloat(params.data.paid_amount_local_currency) + (parseFloat(params.data.paid_amount_local_currency) * (parseFloat(params.data.tax) / 100)) + (parseFloat(params.data.paid_amount_local_currency) * (parseFloat(params.data.withholding_tax) / 100))).toFixed(2)
    },
    fromDateGetter (params) {
      return this.dateFormatter(params.data.from_date)
    },
    toDateGetter (params) {
      return this.dateFormatter(params.data.to_date)
    },
    taxGetter (params) {
      return this.percentageFormatter(params.data.tax)
    },
    withholdingTaxGetter (params) {
      return this.percentageFormatter(params.data.withholding_tax)
    },
    holdingNameGetter (params) {
      return params.data.holding_id ? `${params.data.holding_name} (${params.data.holding_id})` : '-'
    },
    externalDspGetter ({ data }) {
      return data.external_dsp_id ? `${data.external_dsp_name} (${data.external_dsp_exchange})` : 'LatinAd'
    },
    updatePaymentsSearchQuery (val) {
      this.paymentsGridApi.setQuickFilter(val)
    },
    getGridData () {
      this.countSelected = 0
      this.paymentsGridApi.showLoadingOverlay()
      this.$http.get(`${appConfig.apiUrl}/panel/campaigns/content-display`, {
        params: {
          filters: this.filters
        }
      })
        .then((response) => {
          this.paymentsGridData = response.data.rowData
          this.paymentsGridApi.hideOverlay()

          // Only show period columns column if filtering by sales period and showConsumptionInRange is true
          const showPeriodColumns = this.filterType.value === 'sales' && this.filters.showConsumptionInRange
          this.chargesGridOptions.columnApi.setColumnVisible('impressions_showed_in_sales_period', showPeriodColumns)
          this.chargesGridOptions.columnApi.setColumnVisible('total_price_consumed', showPeriodColumns)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    dateFormatter (params) {
      return moment(params, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    percentageFormatter (params) {
      return params ? `${parseFloat(params)}%` : '-'
    },
    currencyFormatter (params) {
      const twoDecimals = parseFloat(params.value).toFixed(2)
      const formatted = twoDecimals.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return `$${formatted}`
    },
    getPaymentsContextMenuItems () {
      const selectedItems = this.paymentsGridApi.getSelectedNodes()
      const thereAreSelectedItems = Array.isArray(selectedItems) && selectedItems.length
      return [
        'copy',
        'copyWithHeaders',
        {
          name: 'Exportar seleccionados',
          icon: '<span class="ag-icon ag-icon-save" unselectable="on"></span>',
          disabled: !thereAreSelectedItems,
          subMenu: [
            {
              name: 'CSV (.csv)',
              action: () => {
                this.paymentsGridApi.exportDataAsCsv({onlySelected: true, columnSeparator:';'})
              }
            },
            {
              name: 'Excel (.xlsx)',
              action: () => {
                this.paymentsGridApi.exportDataAsExcel({onlySelected: true})
              }
            }
          ]
        }
      ]
    },
    // Spanish locale texts
    spanishLocaleText () {
      return {
        // for filter panel
        page: 'Pagina',
        more: 'Mas',
        to: 'a',
        of: 'de',
        next: 'Siguente',
        last: 'Último',
        first: 'Primero',
        previous: 'Anteror',
        loadingOoo: 'Cargando...',

        // for set filter
        selectAll: 'Seleccionar Todo',
        searchOoo: 'Buscar...',
        blanks: 'En blanco',

        // for number filter and text filter
        filterOoo: 'Filtrar',
        applyFilter: 'Aplicar Filtro...',
        equals: 'Igual',
        notEqual: 'No Igual',

        // for number filter
        lessThan: 'Menos que',
        greaterThan: 'Mayor que',
        lessThanOrEqual: 'Menos o igual que',
        greaterThanOrEqual: 'Mayor o igual que',
        inRange: 'En rango de',

        // for text filter
        contains: 'Contiene',
        notContains: 'No contiene',
        startsWith: 'Empieza con',
        endsWith: 'Termina con',

        // filter conditions
        andCondition: 'Y',
        orCondition: 'O',

        // the header of the default group column
        group: 'Grupo',

        // tool panel
        columns: 'Columnas',
        filters: 'Filtros',
        valueColumns: 'Valos de las Columnas',
        pivotMode: 'Modo Pivote',
        groups: 'Grupos',
        values: 'Valores',
        pivots: 'Pivotes',
        toolPanelButton: 'BotonDelPanelDeHerramientas',

        // other
        noRowsToShow: 'No hay filas para mostrar. Por favor realice una búsqueda.',

        // enterprise menu
        pinColumn: 'Columna Pin',
        valueAggregation: 'Agregar valor',
        autosizeThiscolumn: 'Autoajustar esta columna',
        autosizeAllColumns: 'Ajustar todas las columnas',
        groupBy: 'agrupar',
        ungroupBy: 'desagrupar',
        resetColumns: 'Reiniciar Columnas',
        expandAll: 'Expandir todo',
        collapseAll: 'Colapsar todo',
        toolPanel: 'Panel de Herramientas',
        export: 'Exportar',
        csvExport: 'Exportar a CSV',
        excelExport: 'Exportar a Excel (.xlsx)',
        excelXmlExport: 'Exportar a Excel (.xml)',


        // enterprise menu pinning
        pinLeft: 'Pin Izquierdo',
        pinRight: 'Pin Derecho',


        // enterprise menu aggregation and status bar
        sum: 'Suman',
        min: 'Minimo',
        max: 'Maximo',
        none: 'nada',
        count: 'contar',
        average: 'promedio',

        // standard menu
        copy: 'Copiar',
        copyWithHeaders: 'Copiar con cabeceras',
        paste: 'Pegar'
      }
    },
    // Data mappings
    paymentStatusMapping () {
      return {
        pending: 'Pendiente',
        approved: 'Aprobado',
        in_process: 'Procesando',
        in_mediation: 'En mediación',
        rejected: 'Rechazado',
        cancelled: 'Cancelado',
        refunded: 'Reintegrado',
        charge_back: 'Contracargo'
      }
    },
    simplePaymentStatusMapping () {
      return {
        0: 'No pagado',
        1: 'Pagado'
      }
    },
    resetFilters () {
      this.paymentsGridApi.setFilterModel(null)
      this.paymentsGridApi.setSortModel(null)
    },
    onSelectionChanged () {
      const selectedRows = this.paymentsGridApi.getSelectedRows()
      this.countSelected = selectedRows.length
      this.selectedRows = selectedRows
    },
    openPopupChangeState (type) {
      this.popupActiveChangeStateType = type
      switch (type) {
      case 'invoiced':
        this.popupActiveChangeStateTitle = 'Cambiar estado facturado'
        break
      case 'charged':
        this.popupActiveChangeStateTitle = 'Cambiar estado cobrado'
        break
      default:
        break
      }
      this.popupActivePaymentState = !this.popupActivePaymentState
    },
    changeState () {
      this.selectedRows.forEach((row) => {
        this.apiCall(row)
      })
      this.popupActivePaymentState = !this.popupActivePaymentState
    },
    apiCall (row) {
      const { display_id, company_name, content_display_id } = row
      this.paymentsGridApi.showLoadingOverlay()
      const titleText = `Pantalla ID: ${display_id}, Empresa:  ${company_name}`
      this.$http.post(`${appConfig.apiUrl}/panel/content-display/${content_display_id}/payed`, {
        params: {}
      })
        .then((response) => {
          this.$vs.notify({
            title: titleText,
            text: `${response.data.is_payed ? 'PAGADO' : 'NO PAGADO'}`,
            color: 'primary'
          })
          const key = this.paymentsGridData.findIndex(
            (paymentGrid) =>
              paymentGrid.content_display_id === content_display_id
          )
          if (!key) throw new Error('row no exist')
          this.paymentsGridData[key].is_payed = response.data.is_payed ? 1 : 0

          this.paymentsGridApi.refreshCells({ columns: ['is_payed'] })
          this.paymentsGridApi.hideOverlay()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            color: 'danger'
          })
        })
    },
    openPoModal (data) {
      this.poModalActive = true
      this.poModalData = JSON.parse(data)
    }
  },
  beforeMount () {
    this.frameworkComponents = {
      payedRenderer: PayedRenderer
    }
  },
  mounted () {
    this.paymentsGridApi = this.paymentsGridOptions.api
    // this.getGridData()
  }
}
</script>
